import { z } from "zod";

export const fullAddressSchema = z.object({
  manualMsa: z.boolean().nullish(),
  city: z.string(),
  region: z.string().optional(),
  state: z.string(),
  stateCode: z.string(),
  country: z.string(),
  countryCode: z.string(),
  formatted: z.string(),
  metropolitanStatisticalArea: z.string(),
  combinedStatisticalArea: z.string().nullish(),
  streetNumber: z.string().optional(),
  streetName: z.string().optional(),
  postalCode: z.string().optional(),
  line1: z.string().optional(),
  subpremise: z.string().optional(),
});

export type FullAddress = z.infer<typeof fullAddressSchema>;
