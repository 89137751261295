import { forceCast, isDefined } from "@clipboard-health/util-ts";

/**
 * Converts an object into a query string.
 *
 * This function takes an object where the keys are parameter names and the values are parameter values,
 * and converts it into a query string. Includes all key-value pairs from the object, separated by ampersands (`&`).
 *
 * If a value is `undefined` or `null`, it is not included in the query string.
 *
 * @param params - An object containing the parameters to be included in the query string. The keys should be strings,
 * and the values should be either strings, numbers, or booleans. Other types of values are not supported.
 *
 * @returns A query string that includes all key-value pairs from the `params` object.
 *
 * @example
 * ```typescript
 * const params = { name: 'John', age: 30 };
 * const queryString = getQueryString(params);
 * console.log(queryString); // Outputs: 'name=John&age=30'
 * ```
 */
export function getQueryString(params: Record<string, unknown>): string {
  const searchParams = new URLSearchParams();

  Object.entries(params).forEach(([key, value]) => {
    if (isDefined(value)) {
      searchParams.append(key, forceCast<string>(value));
    }
  });

  return searchParams.toString();
}
