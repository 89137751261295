import { useEffect } from "react";

import { logEvent } from "./log";

/**
 * Log an event for the event name. We only log once, regardless of subsequent payload or enabled changes.
 * This ensures that we don't repeatedly log due to shallow changes to the payload object,
 * even if the payload contents are stable.
 * We may adjust this in the future, by comparing the payload values against prior payload values.
 */

export function useLogEffect(
  eventName: string,
  payload?: Record<string, any>,
  options?: { enabled?: boolean }
) {
  const { enabled = true } = options ?? {};

  useEffect(() => {
    if (enabled) {
      logEvent(eventName, payload);
    }
    // Intentionally not including payload in the dependency array, to ensure that we only log once.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventName, enabled]);
}
